<script setup>

import {ref, Transition, watch, onMounted, computed} from "vue";
import CustomCheck from "@/components/coffee/CustomCheck.vue";
import Toggler from "@/components/coffee/Toggler.vue";
import coffee_bag from "@/assets/image/coffee/coffee_bag.png";
import CoffeeAlert from "@/components/coffee/CoffeeAlert.vue";
import Exclamation from "@/components/Exclamation.vue";
import {useCoffeeStore} from "@/stores/coffeeStore.js";

const coffeeStore = useCoffeeStore()
const emits = defineEmits(['update:bagCount', 'update:etcBag', 'update:etcRequest', 'goNext'])
const props = defineProps({
  bagCountValue: {
    type: Number,
    default: 0,
    required: false
  },
  etcBagValue: {
    type: String,
    default: '',
    required: false
  },
  etcRequestValue: {
    type: String,
    default: '',
    required: false
  }
})
const alertIsShown = ref(false)
const alertBody = ref('')

const showAlert = (body) => {
  alertBody.value = body
  alertIsShown.value = true
}


const bagCount = ref(props.bagCountValue)
const etcBag = ref(props.etcBagValue)
const etcRequest = ref(props.etcRequestValue)

const goNext = () => {
  emits('goNext')
}
const bacCountDown = () => {
  bagCount.value = Math.max(0, bagCount.value - 1)
  emits('update:bagCount', bagCount.value)
}
const bagCountUp = () => {
  bagCount.value = Math.min(9, bagCount.value + 1)
  emits('update:bagCount', bagCount.value)
}

const updateEtcBag = (e) => {
  etcBag.value = e.target.value
  emits('update:etcBag', etcBag.value)
}

const updateEtcRequest = (e) => {
  etcRequest.value = e.target.value
  emits('update:etcRequest', etcRequest.value)
}

const validate = () => {
  if (bagCount.value === 0 && !etcBag.value) {
    // showAlert('수거 가방 혹은 기타 배출 용기 중 하나를 반드시 선택해주세요.')
    return false
  }
  return true
}
const isValid = computed(() => {
  if (bagCount.value === 0 && !etcBag.value) {
    // showAlert('수거 가방 혹은 기타 배출 용기 중 하나를 반드시 선택해주세요.')
    return false
  }
  return true
})

const disposeStandard = computed(() => {
  let bcode = coffeeStore?.cafe?.data?.region?.bcode
  if (bcode === '11470') {
    // 양천구
    return '5kg'
  } else if (bcode === '41210') {
    // 광명시
    return '30kg'
  } else if (bcode === '28185') {
      // 인천 연수구
    return '5kg'
  } else if (bcode === '28110') {
      // 인천 서구
    return '5kg'
  } else if (bcode === '11650') {
    // 서초구
    return '10kg'
  } else {
    //기타
    return '30kg'
  }
})
</script>

<template>
  <div>
    <div class="mb-12">
      <div class="page-title mb-2">배출량 선택</div>
    <div class="page-sub-title mb-2">배출하실 커피박 양을 선택하세요.</div>
    <Exclamation>1회 배출량 {{disposeStandard}} 이상</Exclamation>
    </div>

    <!--수거 가방 시작-->
    <div class="mb-9">
      <div class="label mb-2">수거 가방</div>
      <div class="flex justify-space-between items-center">
        <div class="page-sub-title">최대 9개 까지 선택</div>
        <div class="counter-area">
          <button :class="['counter-btn']" :disabled="bagCount === 0"
                  @click="bacCountDown">
            <svg fill="none" height="4" viewBox="0 0 16 4" width="16" xmlns="http://www.w3.org/2000/svg">
              <path clip-rule="evenodd"
                    d="M0 2.00008C0 1.34204 0.533448 0.808594 1.19149 0.808594H14.8085C15.4666 0.808594 16 1.34204 16 2.00008C16 2.65812 15.4666 3.19157 14.8085 3.19157H1.19149C0.533448 3.19157 0 2.65812 0 2.00008Z"
                    fill="#B7B7B7"
                    fill-rule="evenodd"/>
            </svg>
          </button>
          <div :class="['counter-no', bagCount === 0 ? '' : 'active']">{{ bagCount }}</div>
          <button :class="['counter-btn']" :disabled="bagCount === 9" @click="bagCountUp">
            <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
              <path clip-rule="evenodd"
                    d="M9.19184 1.19149C9.19184 0.533448 8.65839 0 8.00035 0C7.34231 0 6.80886 0.533448 6.80886 1.19149V6.80903H1.19149C0.533448 6.80903 0 7.34248 0 8.00052C0 8.65856 0.533448 9.19201 1.19149 9.19201H6.80886V14.8085C6.80886 15.4666 7.34231 16 8.00035 16C8.65839 16 9.19184 15.4666 9.19184 14.8085V9.19201H14.8085C15.4666 9.19201 16 8.65856 16 8.00052C16 7.34248 15.4666 6.80903 14.8085 6.80903H9.19184V1.19149Z"
                    fill="#B7B7B7"
                    fill-rule="evenodd"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!--수거 가방 끝-->
    <!--기타 배출 용기 시작-->
    <div class="mb-[36px]">
      <div class="mb-[8px]">
        <label class="label mb-2" for="etcBag">기타 배출 용기</label>
      </div>
      <div class="mt-[8px]">
        <input id="etcBag" :value="etcBag" class="input" name="etcBag" placeholder="상세 입력"
               type="text" @input="updateEtcBag"/>
      </div>
    </div>
    <!--기타 배출 용기 끝-->
    <!--요청사항 시작-->
    <div class="mb-9">
      <div class="label mb-1">기타 요청사항</div>
      <textarea :value="etcRequest" class="detail-textarea" placeholder="내용 입력"
                @input="updateEtcRequest"/>
    </div>
    <!--요청사항 끝-->


    <!-- 버튼 영역 시작 -->
    <div class="flex w-full justify-space-between gap-x-[8px]">
      <router-link :to="{name: 'coffee-index'}" class="white-btn max-w-[88px]">취소</router-link>
      <div class="w-full">
        <button :disabled="!isValid" class="primary-btn w-full" @click="goNext">다음</button>
      </div>
    </div>
  </div>
  <CoffeeAlert :body="alertBody" :show="alertIsShown" @close="alertIsShown = false"/>
</template>

<style scoped>

.page-sub-title {
  color: #555;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}


.counter-area {
  width: 35%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 12px;
}

.counter-no {
  color: #B7B7B7;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.counter-no.active {
  color: #111;
  font-weight: 700;
  line-height: 26px; /* 162.5% */
}

.counter-btn {
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #DFEDFF;
}

.counter-btn:disabled {
  background: #F5F5F5;
}

.counter-btn > svg > path {
  fill: #3182FF
}

.counter-btn:disabled > svg > path {
  fill: #B7B7B7;
}


.detail-textarea {
  width: 100%;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #DFDFDF;
  min-height: 110px;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.detail-textarea:focus {
  outline: none;
  border: 1px solid #3182FF;
}

.detail-textarea::placeholder {
  color: #B7B7B7;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
</style>